import React from "react";
import theme from "theme";
import { Theme, Strong, Text, Link, Box, Image, Section, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Мийка та сервіс Mastery Insight Hub
			</title>
			<meta name={"description"} content={"Ми розуміємо, наскільки важливо підтримувати автомобіль у відмінному стані. Mastery Insight Hub – це не просто автосервіс і автомийка. Це місце, де ваш автомобіль отримає професійний догляд, а ви – спокій та впевненість у безпеці на дорозі."} />
			<meta property={"og:title"} content={"Мийка та сервіс Mastery Insight Hub"} />
			<meta property={"og:description"} content={"Ми розуміємо, наскільки важливо підтримувати автомобіль у відмінному стані. Mastery Insight Hub – це не просто автосервіс і автомийка. Це місце, де ваш автомобіль отримає професійний догляд, а ви – спокій та впевненість у безпеці на дорозі."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"} />
			<meta name={"msapplication-TileColor"} content={"#0CAD1C"} />
		</Helmet>
		<Components.Header12>
			<Override slot="link1" />
			<Override slot="text" />
			<Override slot="link3" />
		</Components.Header12>
		<Section padding="120px 0 120px 0" background="--color-darkL1" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Strong
						font="--headline1"
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Мийка та сервіс Mastery Insight Hub
					</Strong>
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Ми розуміємо, наскільки важливо підтримувати автомобіль у відмінному стані. Mastery Insight Hub – це не просто автосервіс і автомийка. Це місце, де ваш автомобіль отримає професійний догляд, а ви – спокій та впевненість у безпеці на дорозі.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/kontakti"
						padding="10px 24px 10px 24px"
						color="--orange"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						border-width="2px"
						border-style="solid"
						border-color="--color-secondary"
						sm-margin="0px 0px 0px 0px"
						sm-text-align="center"
						lg-margin="0px 0px 0px 0px"
						sm-width="100%"
						hover-border-color="--color-orange"
						hover-color="--orange"
						hover-transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
						transition="color 0.2s ease-in-out 0s,border 0.2s ease-in-out 0s"
					>
						Контакти
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="40%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://uploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13:32:04.674Z"
					width="100%"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
					srcSet="https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13%3A32%3A04.674Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13%3A32%3A04.674Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13%3A32%3A04.674Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13%3A32%3A04.674Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13%3A32%3A04.674Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13%3A32%3A04.674Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/d06cd4e7c555bbb96039e048a1b259cc.webp?v=2024-11-26T13%3A32%3A04.674Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section background="#ffffff" padding="90px 0 110px 0" quarkly-title="Team-13">
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				grid-template-columns="repeat(2, 1fr)"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					padding="0px 80px 0px 0px"
					width="40%"
					lg-padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
					color="--greyD2"
					sm-padding="0px 0 0px 0px"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--dark" font="600 46px --fontFamily-sans">
						Які послуги надаємо?
					</Text>
					<Text color="--greyD2" font="--lead">
						Mastery Insight Hub: професійний догляд за вашим автомобілем за доступними цінами.
					</Text>
					<Link
						href="/services"
						text-decoration-line="initial"
						color="--light"
						font="--lead"
						hover-opacity="0.9"
						background="--color-indigo"
						border-radius="8px"
						padding="11px 24px 12px 24px"
						text-align="center"
						width="250px"
						margin="2rem auto 0px auto"
						display="inline-block"
					>
						{"        "}Дізнатися більше{"\n      "}
					</Link>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="60%"
					display="grid"
					flex-direction="column"
					lg-width="100%"
					grid-template-columns="repeat(2, 1fr)"
					grid-template-rows="repeat(3, 1fr)"
					grid-gap="46px 24px"
					sm-grid-template-columns="1fr"
					sm-grid-template-rows="auto"
					sm-grid-gap="36px 0"
				>
					<Box
						min-width="100px"
						min-height="100px"
						sm-display="flex"
						sm-flex-direction="row"
						display="flex"
						flex-direction="row"
					>
						<Box min-width="100px" min-height="100px" sm-margin="0px 0px 0 0px">
							<Image
								src="https://uploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13:32:04.681Z"
								display="block"
								width="100px"
								border-radius="50%"
								lg-max-width="100%"
								max-width="100%"
								object-fit="cover"
								max-height="100%"
								height="100px"
								srcSet="https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13%3A32%3A04.681Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13%3A32%3A04.681Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13%3A32%3A04.681Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13%3A32%3A04.681Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13%3A32%3A04.681Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13%3A32%3A04.681Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/vista-dall-alto-di-un-meccanico-maschio-anziano-che-ripara-il-motore-di-un-auto-in-officina_130388-2970.jpg?v=2024-11-26T13%3A32%3A04.681Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
							margin="0 0px 0px 25px"
							sm-margin="0 0px 0px 18px"
						>
							<Text margin="8px 0px 0px 0px" color="--primary" font="--link">
								Комплексне технічне обслуговування автомобіля.
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						sm-display="flex"
						sm-flex-direction="row"
						display="flex"
						flex-direction="row"
					>
						<Box min-width="100px" min-height="100px" sm-margin="0px 0px 0 0px">
							<Image
								src="https://uploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13:32:04.670Z"
								display="block"
								width="100px"
								border-radius="50%"
								lg-max-width="100%"
								max-width="100%"
								object-fit="cover"
								max-height="100%"
								height="100px"
								srcSet="https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13%3A32%3A04.670Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13%3A32%3A04.670Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13%3A32%3A04.670Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13%3A32%3A04.670Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13%3A32%3A04.670Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13%3A32%3A04.670Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/carrepair-img-9.jpg?v=2024-11-26T13%3A32%3A04.670Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
							margin="0 0px 0px 25px"
							sm-margin="0 0px 0px 18px"
						>
							<Text margin="8px 0px 0px 0px" color="--primary" font="--link">
								Сучасна діагностика.
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						sm-display="flex"
						sm-flex-direction="row"
						display="flex"
						flex-direction="row"
					>
						<Box min-width="100px" min-height="100px" sm-margin="0px 0px 0 0px">
							<Image
								src="https://uploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13:32:04.691Z"
								display="block"
								width="100px"
								border-radius="50%"
								lg-max-width="100%"
								max-width="100%"
								object-fit="cover"
								max-height="100%"
								height="100px"
								srcSet="https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13%3A32%3A04.691Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13%3A32%3A04.691Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13%3A32%3A04.691Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13%3A32%3A04.691Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13%3A32%3A04.691Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13%3A32%3A04.691Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/polo-on-hoist-1-1024x976.png?v=2024-11-26T13%3A32%3A04.691Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
							margin="0 0px 0px 25px"
							sm-margin="0 0px 0px 18px"
						>
							<Text margin="8px 0px 0px 0px" color="--primary" font="--link">
								Ремонт будь-якої складності.
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						sm-display="flex"
						sm-flex-direction="row"
						display="flex"
						flex-direction="row"
					>
						<Box min-width="100px" min-height="100px" sm-margin="0px 0px 0 0px">
							<Image
								src="https://uploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13:32:04.707Z"
								display="block"
								width="100px"
								border-radius="50%"
								lg-max-width="100%"
								max-width="100%"
								object-fit="cover"
								max-height="100%"
								height="100px"
								srcSet="https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13%3A32%3A04.707Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13%3A32%3A04.707Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13%3A32%3A04.707Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13%3A32%3A04.707Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13%3A32%3A04.707Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13%3A32%3A04.707Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/auto-1822415_1280.jpg?v=2024-11-26T13%3A32%3A04.707Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
							margin="0 0px 0px 25px"
							sm-margin="0 0px 0px 18px"
						>
							<Text margin="8px 0px 0px 0px" color="--primary" font="--link">
								Хімчистка салону та миття автомобіля.
							</Text>
						</Box>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						sm-display="flex"
						sm-flex-direction="row"
						display="flex"
						flex-direction="row"
					>
						<Box min-width="100px" min-height="100px" sm-margin="0px 0px 0 0px">
							<Image
								src="https://uploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13:32:04.683Z"
								display="block"
								width="100px"
								border-radius="50%"
								lg-max-width="100%"
								max-width="100%"
								object-fit="cover"
								max-height="100%"
								height="100px"
								srcSet="https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13%3A32%3A04.683Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13%3A32%3A04.683Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13%3A32%3A04.683Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13%3A32%3A04.683Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13%3A32%3A04.683Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13%3A32%3A04.683Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6745953c1b94b1002432de16/images/Dentrepair_56693085_original-copy.webp?v=2024-11-26T13%3A32%3A04.683Z&quality=85&w=3200 3200w"
								sizes="(max-width: 576px) 100vw,(max-width: 767px) 100vw,(max-width: 992px) 100vw,100vw"
							/>
						</Box>
						<Box
							min-width="100px"
							min-height="100px"
							display="flex"
							flex-direction="column"
							justify-content="center"
							sm-width="100%"
							sm-padding="0px 0px 0px 0"
							margin="0 0px 0px 25px"
							sm-margin="0 0px 0px 18px"
						>
							<Text margin="8px 0px 0px 0px" color="--primary" font="--link">
								Захисне покриття для кузова.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Box
			padding="80px 72px 80px 72px"
			background="linear-gradient(0deg,--color-lightD2 0%,rgba(248, 243, 243, 0.79) 100%),rgba(0, 0, 0, 0) url(https://images.unsplash.com/photo-1575936123477-77ff4e911fa9?crop=entropy&cs=tinysrgb&fit=max&ixid=MXwyMDg4fDB8MHxwaG90by1wYWNrZWR8NXx8Y2FyfGVufDB8fHx8fDE2MzgzNjEzMjA&ixlib=rb-1.2.1&q=80&w=1080) bottom/cover no-repeat scroll padding-box"
			border-radius="24px"
			margin="0px 0px 0 0px"
			sm-padding="60px 36px 60px 36px"
		>
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 'Source Sans Pro', sans-serif"
				>
					<Strong>
						Чому обирають нас?
					</Strong>
				</Text>
				<Text margin="0px 0px 25px 0px" font="--link" color="--greyD2" md-text-align="center">
					Наші автомийки працюють із використанням безпечних для довкілля засобів і технологій, щоб забезпечити чистоту вашого авто без шкоди для природи.
				</Text>
				<Box
					lg-flex-wrap="wrap"
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="16px"
					md-grid-template-columns="repeat(2, 1fr)"
					md-grid-gap="36px"
					sm-grid-template-columns="repeat(1, 1fr)"
					sm-grid-gap="8px"
					width="100%"
				>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							Професійність
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Наші сертифіковані майстри мають багаторічний досвід і використовують сучасне обладнання для виконання будь-яких ремонтних та діагностичних робіт.
							<br />
							<br />
						</Text>
					</Box>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							Індивідуальний підхід
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Ми слухаємо клієнтів, аналізуємо кожен запит і пропонуємо оптимальні рішення для вашого автомобіля.
							<br />
							<br />
						</Text>
					</Box>
					<Box
						sm-margin="0px 0px 30px 0px"
						sm-width="100%"
						display="flex"
						flex-direction="column"
						justify-content="space-between"
						md-align-items="center"
					>
						<Icon
							category="fa"
							icon={FaCheckCircle}
							size="28px"
							margin="16px 0px 16px 0px"
							color="--orange"
							md-margin="0px 0px 16px 0px"
						/>
						<Text
							margin="0px 0px 18px 0px"
							color="--darkL2"
							font="--headline4"
							lg-text-align="left"
							md-font="--lead"
							md-text-align="center"
						>
							Швидкість та якість
						</Text>
						<Text
							margin="0px 0px 0px 0px"
							color="--greyD2"
							font="--base"
							lg-text-align="left"
							md-text-align="center"
						>
							Ми цінуємо ваш час, тому гарантуємо виконання робіт у найкоротші терміни без втрати якості.
							<br />
							<br />
						</Text>
					</Box>
				</Box>
			</Box>
			<Link
				href="/services"
				text-decoration-line="initial"
				color="--light"
				font="--lead"
				hover-opacity="0.9"
				background="--color-indigo"
				border-radius="8px"
				padding="11px 24px 12px 24px"
				text-align="center"
				width="250px"
				margin="2rem auto 0px auto"
				display="inline-block"
			>
				{"        "}Дізнатися більше{"\n      "}
			</Link>
		</Box>
		<Components.Faq1324 />
		<Components.Bene />
		<Components.Footer123>
			<Override slot="link">
				0933583838
			</Override>
		</Components.Footer123>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"614ce40335c5c8001f7746de"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});